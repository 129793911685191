// Brand colours.
$opal-green-colour: #00665e;
$jet-black-colour: #121212;
$ocean-blue-colour: #004f59;

$light-background-colour: #ebf3f2;
$danger-colour: #ad1b18;

$signature-font: AstonMartinFlare, 'Noto Sans', Arial, sans-serif;
$support-font: AstonMartinSans, 'Noto Sans', Arial, sans-serif;

@use '~@cognitranlimited/itis-web/dist/styles' with (
    $colour-accent: $opal-green-colour,
    $accent-text-colour: #fff,
    $body-background: #fff,
    $body-text-colour: $jet-black-colour,
    $body-font-family: $support-font,
    $heading-font-family: $signature-font,
    $NewLookPanel-background: $light-background-colour,
    $heading-text-colour: $jet-black-colour,
    $Panel-header-text-transform: uppercase,
    $Button-font-family: $signature-font,
    $Button-font-weight: normal,
    $Button-font-size: 12px,
    $Button-background: $ocean-blue-colour,
    $Button-border-radius: 18px,
    $Button-padding: 8px 16px,
    $Link-text-colour: $ocean-blue-colour,
    $Link-text-colour-hover: black,
    $Header-shadow: none
);

@use '~@cognitranlimited/itis-web/dist/styles/check-sheets';
@use '~@cognitranlimited/itis-web/dist/styles/vehicle-park';
@use '~@cognitranlimited/itis-web/dist/styles/campaigns';

@use '@fontsource/noto-sans/scss/mixins' as NotoSans;
@include NotoSans.fontFace();

@font-face {
    font-family: AstonMartinSans;
    font-weight: 100;
    font-style: normal;
    font-display: swap;
    src: url('./fonts/AstonMartinSans_Lt.woff2') format('woff2');
}

@font-face {
    font-family: AstonMartinSans;
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('./fonts/AstonMartinSans_Rg.woff2') format('woff2');
}

@font-face {
    font-family: AstonMartinSans;
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url('./fonts/AstonMartinSans_Md.woff2') format('woff2');
}

@font-face {
    font-family: AstonMartinFlare;
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('./fonts/AstonMartinFlare_Rg.woff2') format('woff2');
}

@font-face {
    font-family: AstonMartinFlare;
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url('./fonts/AstonMartinFlare_Md.woff2') format('woff2');
}

@font-face {
    font-family: AstonMartinFlare;
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url('./fonts/AstonMartinFlare_Bd.woff2') format('woff2');
}

.VehicleHistoryButtons {
    display: flex;
    gap: 5px;

    button {
        margin: 0px !important;
    }
}

div.HeaderSearch div.SearchBox input[type=text]::placeholder {
    color: #ffffff;
    opacity: 0.7;
}

div.HeaderSearch div.SearchBox.SearchBox-large input[type=text] {
    background: $opal-green-colour;
    color: #ffffff;
}

div.HeaderSearch-options div.SearchOptions {
    background: $opal-green-colour;
    color: #ffffff;
}

div.HeaderSearch-options h4 {
    color: #ffffff;
}

div.HeaderSearch-options div.SearchOptions .TickBox .MuiCheckbox-colorPrimary.Mui-checked {
    color: #ffffff;
}

div.HeaderSearch-options div.SearchOptions .TickBox .MuiCheckbox-colorPrimary.MuiCheckbox-colorPrimary {
    color: #ffffff;
}

div.HeaderSearch div.SearchBox.SearchBox-large .SearchBox-icon {
    color: #ffffff;
}

div.HeaderSearch div.SearchBox.SearchBox-large input:not([value=""]) {
    border-radius: 0;
}

div.HeaderSearch div.SearchBox-right .SearchBox-clear {
    background: $opal-green-colour;
}

div.HeaderSearch div.SearchBox button {
    color: #ffffff;
}

div.HeaderSearch div.SearchBox-right .SearchBox-clear:hover {
    color: #ffffff;
}

.Header {
    border-bottom: 2px solid rgba(0, 0, 0, 0.15);
}

// snackbar notifier styles
.MuiSnackbarContent-root {
    background: $opal-green-colour !important;
}

// searchbar styles
.HeaderSearch  {
    border: 3px solid $opal-green-colour;
    border-radius: 3px;

    .SearchBox {
        background-color: transparent;
        height: 36px;
        margin: 5px 0px 5px 5px;
        padding-right: 5px;
        box-shadow: none;
    }

    .HeaderSearch-options {
        width: calc(100% + 6px);
        margin-left: -3px;
        margin-top: 1px;
    }
}

// Links in email style
.MessageView-body a {
    font-weight: bold;
    text-decoration: underline;
}

// fixes search options tickbox color
.HeaderSearch {
    .MuiCheckbox-root {
        color: white !important;
    }
}

// TODO allow changing with variable
.SearchOptions-helpButton svg {
    color: white !important;
}

.SearchBox-large .SearchBox-clear {
    margin: 0 !important;
}

.SearchSuggestions {
    background: $opal-green-colour;
    color: #ffffff;
}

.SearchSuggestions-option:hover {
    background: #1a716a;
}

.SearchSuggestions-icon, .SearchSuggestions-setSearchButton {
    color: #ffffff;
}
